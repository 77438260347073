@charset "utf-8";
@import "settings";
//@import "assets/vendor/zurb/foundation/assets/foundation";
@import "assets/vendor/zurb/foundation/scss/foundation";
// @import "assets/components/motion-ui/motion-ui";
@import "aktiv-base";
@import "slick";
@import "slick-lightbox";
// Or selectively include components
// Global styles //
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;
// Grids (choose one)
//@include foundation-xy-grid-classes;
// @include foundation-grid;
@include foundation-flex-grid;
// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;
// Containers
@include foundation-accordion;
@include foundation-media-object;
//@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;
// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;
// Helpers
//@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;
// Motion UI
//@include motion-ui-transitions;
//@include motion-ui-animations;
// FOR IE 10
@-ms-viewport {
  width: device-width;
}
// FONTS
//////////////////////////////////////////////////////////////////////////
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,700&display=swap');
// MIXINS
//////////////////////////////////////////////////////////////////////////
@function f-header-size($breakpoint, $size) {
  @return rem-calc(map-get(map-get(map-get($header-styles, $breakpoint), $size), "font-size") * 1px);
}
@mixin header-size($type) {
  font-size: rem-calc(map-get(map-get(map-get($header-styles, "small"), $type), "font-size") * 1px);
  @include breakpoint(medium) {
    font-size: rem-calc(map-get(map-get(map-get($header-styles, "medium"), $type), "font-size") * 1px);
  }
}
@mixin stak {
  // defaults;
  padding: 20px 0;
  background-color: $white;
  p {
    font-size: 1em;
    &.lead {
      font-size: 100%;
      line-height: 1.6;
    }
  }
  @include breakpoint(medium) {
    // enhancements;
    padding: 41px 0;
  }
  @include breakpoint(large) {
    // enhancements;
    padding: 60px 0;
    p {
      font-size: 1em;
      &.lead {
        font-size: 125%;
        line-height: 1.65;
      }
    }
  }
}
@mixin hero-slide {
  // defaults;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover !important;
  z-index: 0;
  overflow: hidden;
  background-position: center center;
  backface-visibility: hidden;
  align-items: center;
  display: flex;
  @include breakpoint(medium) {
    // enhancements;
    height: 400px;
  }
  @include breakpoint(large) {
    // enhancements;
    height: 600px;
  }
  @include breakpoint(xlarge) {
    // enhancements;
    height: 701px;
  }
}
@mixin no-bullet {
  list-style-type: none;
  margin: 0;
}
@mixin stak-pic {
  @include stak;
  display: table;
  width: 100%;
  min-height: 300px;
  .row {
    display: table-row;
    width: 100%;
  }
  .columns {
    display: table-cell;
    float: none;
    vertical-align: middle;
    text-align: center;
  }
  .column:last-child:not(:first-child),
  .columns:last-child:not(:first-child) {
    float: none;
  }
  .vertical-center {
    vertical-align: middle;
  }
  h2 {
    //color: $white;
    line-height: initial;
    span {
      background-color: rgba($body-font-color, 0.8);
      padding: 0 0.25em;
      margin: 0;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    }
    strong {
      color: $black;
      display: inline-block;
    }
  }
  h3,
  li,
  p,
  strong {
    //color: $white;
  }
  @include breakpoint(large) {
    background-attachment: fixed;
  }
}
@mixin fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  left: 0;
  &.expanded:not(.top-bar) {
    height: auto;
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
  }
  &.expanded:not(.top-bar) .title-area {
    position: fixed;
    width: 100%;
    z-index: 99;
  }
  &.expanded:not(.top-bar) .top-bar-section {
    margin-top: 5rem;
    z-index: 98;
  }
}
@mixin radius {
  border-radius: 4px;
}
.foo {
  @include grid-row;
  .bar {
    @include grid-column(12);
  }
}
@media screen and #{breakpoint(medium)} {
  .foo {
    @include grid-row;
    .bar {
      @include grid-column(4);
    }
  }
}
@mixin overlay {
  content: "";
  background: rgba($gold, .5);
  background: linear-gradient(0deg, rgba($gold, .8) 0%, rgba($gold, .75) 20%);
  position: absolute;
  mix-blend-mode: normal;
  opacity: 1;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 0;
  height: 100%;
  width: 100%;
}
@mixin overlay-black {
  content: "";
  background: rgba($black, .2);
  position: absolute;
  mix-blend-mode: normal;
  opacity: 1;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 0;
  height: 100%;
  width: 100%;
}
@mixin colored-stak {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    mix-blend-mode: multiply;
    opacity: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
  }
  &.photo-bgd-callout {
    .photo-bgd {
      &:after {
      }
    }
  }
  .content, .hero-caption {
    position: relative;
    z-index: 2;
  }
}
@mixin overlay-background-img {
  content: "";
  position: absolute;
  mix-blend-mode: normal;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: .8;
}
@mixin button-hover-white {
  &:hover {
    color: $secondary-color;
    box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, .5);
    background-color: $white;
  }
}
// DEFAULTS (* see _settings.scss for FOUNDATION OVERRIDES)
//////////////////////////////////////////////////////////////////////////
$logo-small: 200px;
$logo-medium: 280px;
$logo-large: 350px;
.page-content {
  li {
    margin-bottom: .5em;
  }
}
address {
  font-style: normal;
  margin-bottom: 2em;
  strong {
    color: $black;
  }
}
.columns {
  position: relative;
}
.fixed {
  position: fixed;
  width: 100%;
  z-index: 1;
}
$off-canvas-width: 60%;
.row.expanded {
  max-width: none;
  > .columns {
    padding: 0;
  }
}
:focus {
  outline: none;
}
a.has-tip {
  border-bottom: none;
  color: $primary-color;
  cursor: pointer;
  font-weight: normal;
}
.slick-arrow {
  z-index: 1;
}
.go-button {
  @include button;
  @include header-size(h5);
  margin-top: 1em;
  color: $white !important;
  overflow: hidden;
  width: 250px;
  cursor: pointer;
  white-space: nowrap;
  transition-timing-function: linear;
  transition: all 0.2s ease;
  margin: 0 auto 1em;
  position: relative;
  &:before {
    content: '';
    background: darken($primary-color, 10%) url("../images/site/arrowhead.png") center center no-repeat;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition-timing-function: linear;
    transform: translateZ(0) scale(1, 1.1) translate(-101%, 0);
    transition: all 0.2s ease-in-out 0s;
  }
  &:hover:before {
    transform: translateZ(0) scale(1, 1.1) translate(0%, 0);
  }
}
ul.link-list {
  list-style: none;
  margin: 0 auto;
  li:after {
    content: "\203A \20";
    padding-left: 0.25em;
  }
  li:hover:after {
    color: $gold;
  }
  a {
    display: inline-block;
    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 0;
      margin: 0.25em 0 0;
      background: transparent;
      transition: width 0.25s ease, background-color 0.25s ease;
    }
    &:hover:after {
      width: 100%;
      background: $gold;
    }
  }
}
ul.row {
  list-style: none;
  li {
    margin: 0.5em 0;
    line-height: 1.225em;
  }
}
.stak-pic {
  @include stak-pic;
}
// prevent mobile no-scroll
.slick-slider {
  touch-action: auto;
  -ms-touch-action: auto;
  img {
    margin: auto;
  }
}
// WordPress alignment classes
.aligncenter {
  display: flex;
  flex: 1;
  margin: auto;
}
.alignright {
  float: right;
  margin-left: auto;
  padding: 15px;
}
.alignleft {
  float: left;
  margin-right: auto;
  padding: 15px;
}
.overlay {
  &:after {
    @include overlay;
  }
}
h1, h2, h3, h4 {
  color: $primary-color;
  .subheader {
    font-size: 80%;
    line-height: 1.15em;
  }
}
.button.secondary {
  color: $button-color;
  &:hover {
    background-color: $gold;
    color: $secondary-color;
  }
}
.button {
  &:hover {
    color: $button-color-hover;
  }
  &.red-button {
    background-color: $secondary-color;
    &:hover {
      background-color: $gold;
    }
  }
}
.button-ico {
  cursor: pointer;
  transition: all .2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}
.note {
  font-size: .8rem;
  color: $secondary-color;
}
// GLOBALS (CUSTOM GLOBALS)
//////////////////////////////////////////////////////////////////////////
.gold {
  color: #FFC600;
}
.blue {
  color: #202968;
}
.red {
  color: #E32525;
}
.yellow {
  color: #FEDD00;
}
.skip-link.screen-reader-text {
  display: none;
}
.sticky-container {
  transition: all 0.25s;
  z-index: 999; // for dropdown pre-scroll
}
header.main-nav {
  background-color: rgba($white, 0.95);
  transition: all 0.25s;
  position: relative;
  .logo {
    width: $logo-small;
    margin: 0.5em 0;
    transition: all 0.25s;
  }
  @include breakpoint(medium) {
    .logo {
      width: $logo-medium;
    }
  }
  @include breakpoint(large) {
    // enhancements;
  }
  @include breakpoint(xlarge) {
    .logo {
      width: $logo-large;
    }
  }
  // GLOBAL-MENU ///////////////////////////////////////////////
  ul.global-menu {
    vertical-align: baseline;
    width: 100%;
    justify-content: flex-end;
    text-align: right;
    > li {
      position: relative;
      text-align: left;
      font-family: $header-font-family;
    }
    > li > a {
      font-size: 0.78em;
      padding: 0 1.25em;
      color: $secondary-color;
      line-height: 3em;
      //text-transform: uppercase;
      //font-weight: 600;
      transition: all 0.5s;
      position: relative;
      text-align: left;
      &:last-child {
        padding-right: 0;
      }
      &:hover {
        color: $secondary-color;
      }
      &:after {
        content: '';
        display: block;
        left: 0;
        height: 3px;
        width: 0;
        background: $primary-color;
        transition: width 0.5s ease, background-color 0.25s ease;
      }
      &.hover:after,
      &:hover:after {
        width: 100%;
        background: $primary-color;
      }
      &.sel {
        color: $secondary-color;
        &:after {
          width: 100%;
          background: $secondary-color;
        }
      }
    }
    @include breakpoint(large) {
      > li > a {
        font-size: 1em;
        padding: 0 1.5em;
      }
    }
    @include breakpoint(xlarge) {
      // enhancements;
      > li > a {
        font-size: 1.1em;
        padding: 0 1.5em;
      }
    }
  }
  .dropdown-pane {
    font-size: 0.9em;
    text-align: left;
    background-color: $primary-color;
    border: none;
    &.mega-menu {
      width: 100% !important;
      border: none;
      background-color: transparent;
      padding: 0;
      &.is-open {
        left: 0 !important;
        display: block;
      }
      .content {
        background-color: $white;
        padding: 1.25em;
        border: 1px solid $light-gray;
        a {
          display: inline-block;
          line-height: 1.25em;
          &:hover {
            color: $secondary-color;
          }
        }
        .description {
          border-top: 1px solid $light-gray;
          padding-top: 0.5em;
          color: $gray;
          p {
            line-height: 1.25em;
          }
        }
        .nav-title {
          @include header-size(h5);
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          a {
            color: $gray;
          }
        }
        .menu .button,
        .menu a {
          line-height: 1;
          text-decoration: none;
          display: block;
          padding: 0.7rem 1rem 0.7rem 0;
          font-weight: bold;
        }
        .blocks {
          li {
            display: inline-block !important;
          }
          a {
            font-weight: normal;
            display: inline-block !important;
          }
          .menu-ico {
            max-width: 30px;
            display: inline;
            padding-right: 6px;
            line-height: 1em;
            margin: 0;
          }
        }
      }
      img {
        width: 100%;
        margin: 0.75em 0;
        display: block;
      }
    }
    .vertical.menu {
      a {
        color: $white;
        padding: 0.3rem 1rem;
        font-weight: bold;
        &:hover {
          text-decoration: none;
          color: $secondary-color;
        }
      }
      &.nested {
        a {
          font-weight: normal;
        }
      }
    }
  }
}
.is-stuck {
  header.main-nav {
    background-color: rgba($white, 0.95);
    height: auto;
    @include breakpoint(xlarge) {
      // enhancements;
      .logo {
        width: $logo-medium;
      }
    }
  }
}
// ancillary
.ancillary {
  text-align: right;
  display: block;
  background-color: $primary-color;
  ul.ancillary-menu {
    margin: 0 !important;
    font-size: 0.7em;
    //padding: 0.5em 0;
    list-style-type: none;
    text-transform: uppercase;
    justify-content: flex-end;
    align-items: center;
    li {
      list-style-type: none;
      padding: 0 0 0 10px;
      a {
        color: $white;
        padding: 0.5rem;
        line-height: inherit;
        &:hover {
          color: $secondary-color;
        }
      }
      strong {
        font-size: 1.25em;
        vertical-align: center;
      }
      form {
        display: inline;
      }
    }
  }
  .phone {
    font-weight: bold;
  }
  .contact {
    @include button;
    @include button-hollow;
    font-size: 1em;
    border: lighten($secondary-color, 20%) 1px solid;
    color: $secondary-color;
    margin-bottom: 0;
    transition: all 0.25s;
    margin: 0;
    a {
      color: $secondary-color;
    }
    &:hover {
      border: $secondary-color 1px solid;
    }
  }
  // .x-collapse {
  //    .columns,
  //    .postfix,
  //    a,
  //    button,
  //    input {
  //       margin: 0 !important;
  //    }
  // }
  // input:focus {
  //    outline: none;
  // }
  .search-button {
    font-size: .75em;
    padding: 0 .5em 0 .5em;
    margin: 0 0 0 .25em;
    color: $white;
    line-height: 3em;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    text-align: left;
    display: block;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    &:after {
      position: absolute;
      content: '';
      display: block;
      bottom: 0;
      left: 0;
      height: 6px;
      width: 0;
      background: $white;
      transition: all 0.25s ease;
    }
    &:hover, &:focus {
      color: $secondary-color;
    }
    &.hover {
      color: $white;
      background-color: $secondary-color;
    }
    @include breakpoint(large) {
      font-size: .9em;
    }
  }
  .search-pane {
    background-color: $secondary-color;
    border: none;
    transition: all 0.25s ease;
    input {
      background-color: $secondary-color;
      border: 1px solid $white;
      color: $white;
      box-shadow: none;
      &::placeholder {
        color: $white;
      }
    }
  }
}
// MOBILE NAVIGATION
/////////////////////////////////////
.title-bar {
  text-align: center;
  padding: 0.5em 0 0.25em;
  line-height: 1em;
  .logo {
    width: $logo-small;
    margin: 0 auto;
    padding: 0;
    .fill-black {
      fill: $black;
    }
  }
  .menu-ico {
    //margin: -2px 0.3em;
    border: none;
    background: none;
    z-index: 99999;
    transform: scale3d(0.8, 0.8, 0.8);
    transition: 0.3s;
    cursor: pointer;
    user-select: none;
    position: absolute;
    right: 10px;
    top: 1em;
    span {
      display: inline-block;
      width: 2.5rem;
      height: 0.26rem;
      background: $secondary-color;
      border-radius: 0.5em;
      transition: 0.3s;
      position: relative;
    }
    span:before {
      display: inline-block;
      width: 2.5rem;
      height: 0.26rem;
      background: $secondary-color;
      border-radius: 0.5em;
      transition: all 0.3s;
      position: absolute;
      top: 0.75em;
      left: 0;
      content: '';
      transform-origin: 0.13rem center;
    }
    span:after {
      display: inline-block;
      width: 2.5rem;
      height: 0.26rem;
      background: $secondary-color;
      border-radius: 0.5em;
      transition: all 0.3s;
      position: absolute;
      top: -.75em;
      left: 0;
      content: '';
      transform-origin: 0.13rem center;
      //  box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    }
    &:hover {
      span {
        background: lighten($secondary-color, 10%);
      }
      span:before {
        background: lighten($secondary-color, 10%);
      }
      span:after {
        background: lighten($secondary-color, 10%);
        //  box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
      }
    }
  }
}
.menu-ico[aria-expanded="true"] {
  //background-color: $primary-color;
  span {
    background: transparent !important;
  }
  span:before {
    //box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    transform: rotate3d(0, 0, 1, 45deg);
    transform-origin: 50% 50%;
    top: 0;
    background-color: $secondary-color;
  }
  span:after {
    //box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    transform: rotate3d(0, 0, 1, -45deg);
    transform-origin: 50% 50%;
    top: 0;
    background-color: $secondary-color;
  }
}
.right-off-canvas-menu {
  padding-top: 3.75rem;
  //background-color: $white;
  .menu li a {
    display: block;
    border-bottom-color: darken($gray, 10%);
    border-top: 1px solid rgba($white, 0.15);
    color: $anchor-color;
    &:hover {
      color: $anchor-color-hover;
    }
  }
  .menu li:first-of-type {
    a {
      border-top: none;
    }
  }
  .menu li:last-of-type {
    a {
      border-bottom: none;
    }
  }
  .searchform {
    input {
      padding: 1em;
    }
    .icon-search {
      padding: 0.25rem 0rem;
      &:before {
        font-family: aktiv-base;
        font-size: 1.25em;
        font-style: normal;
        content: "\e801";
      }
    }
  }
}
// GLOBAL FOOTER
/////////////////////////////////////
footer.global-footer {
  background-color: $primary-color;
  padding: 2em 0;
  font-size: 0.9em;
  //*:not(.button){color: $gray;}
  text-align: center;
  color: $white;
  .logo {
    vertical-align: middle;
    margin: 0 auto;
    max-width: $logo-small;
  }
  ul.footer-nav {
    @include no-bullet;
    li {
      padding: 0.15em 0;
      &:first-of-type {
        a {
          color: $gold
        }
      }
    }
    .nav-title {
      margin-bottom: .5em;
      border-bottom: 2px solid $gold;
      display: inline-block;
      font-weight: bold;
      margin-top: 1em;
      color: $gold;
    }
  }
  a {
    color: $white;
    &:hover {
      color: $secondary-color;
    }
  }
  .copyright {
    margin: 1em auto;
    text-align: center;
    p {
      font-size: 0.8em;
      vertical-align: middle;
    }
  }
  .social-icons {
    font-size: 1.25em;
    margin: 1em 0;
    a {
      margin-left: -.5em;
      margin-right: 0.5em;
      color: $gold;
    }
    [class^="icon-"]:hover:after,
    [class*="icon-"]:hover:before {
      color: $secondary-color;
    }
  }
  .telephone {
    display: block;
    margin: 0.5em 0;
  }
  .jobs-btn {
    @include button($expand: true, $background: $white, $style: hollow);
    transition: all, 0.25s;
    border-width: 2px;
    &:hover {
      color: lighten($primary-color, 50%);
      border-color: lighten($primary-color, 50%);
    }
  }
  @include breakpoint(medium) {
    text-align: left;
    h5 {
      margin-top: 0;
    }
    .logo {
      margin: 0;
    }
    .copyright {
      margin: 0;
      text-align: left;
    }
  }
}
.icon-callouts {
  img {
    padding-right: 0.51em;
  }
  a:hover {
    h4 {
      color: $primary-color;
    }
  }
}
.stak {
  @include stak;
  //  background-color: $white;
  &.accordion-stak {
    padding: 30px 0;
  }
}
.blue-stak,
.red-stak,
.gold-stak {
  @include colored-stak;
  h1, h2, h3, h4 {
    .subheader {
      color: $white;
      display: block;
    }
  }
}
.alt-stak {
  @include stak;
  background-color: $light-gray;
}
.blue-stak {
  background-color: $primary-color;
  h2, h3 {
    color: $gold;
  }
  color: $white;
  a:not(.button) {
    color: $yellow;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
}
.red-stak {
  background-color: $secondary-color;
}
.gold-stak {
  background-color: $gold;
  &:not(.cards-stak) {
    .button:hover {
      background-color: $white;
    }
    .button:focus {
      background-color: $primary-color;
    }
  }
}
.bgd-pests {
  background-position: 0 100px;
  //z-index: 999;
  min-height: 640px;
  position: relative;
  z-index: 1;
  &:after {
    background-image: url("../images/bgd-pests.png");
    @include overlay-background-img ;
    z-index: -1;
    //background-size: cover;
  }
}
.swoosh-up {
  @include breakpoint(medium) {
    position: relative;
    mask-image: url('../images/swoosh-up.svg');
    mask-mode: alpha;
    mask-size: 100%;
    mask-position: center top;
    margin-top: -80px;
    padding-top: 80px;
  }
}
.swoosh-down {
  @include breakpoint(medium) {
    position: relative;
    mask-image: url('../images/swoosh-down.svg');
    mask-mode: alpha;
    mask-size: 100%;
    mask-position: center bottom;
    margin-bottom: -80px;
    padding-bottom: 80px;
  }
}
.large-8, .x-large-9 {
  .alt-stak, .blue-stak, .red-stak, .gold-stak {
    border-radius: 1em;
    padding: 2em;
    margin-bottom: 1em;
  }
  .split-image {
    img {
      width: 100%;
    }
  }
  .features-split-stak {
    padding: 0 !important;
    .fill-image {
      .content {
        padding: 0 2em;
      }
    }
  }
}
.blog-heading {
  padding-top: 0px;
  h3 {
    background: linear-gradient(0deg, #E32525 0%, #e11d1d 100%);
    border-radius: 1em;
    color: white;
    padding: .3em 1em;
    border: 1px solid #ffc600;
    margin-bottom: 1em;
  }
}
// HOMEPAGE
//////////////////////////////////////////////////////////////////////////
#home-top-bar {
  width: 100%;
}
.responsive-embed.mobile-home-hero {
  position: relative;
  margin-bottom: 0;
}
img.circle-pest {
  border-radius: 50%;
}
.home-hero {
  position: relative;
  .video-wrapper {
    // position: fixed;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 85vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
    background-color: $primary-color;
    &:after {
      content: "";
      background: rgba($black, .2);
      //background: linear-gradient(45deg, rgba($primary-color, .5) 0%, rgba($primary-color, .75) 50%, rgba($primary-color, .95) 100%);
      position: absolute;
      mix-blend-mode: normal;
      opacity: 1;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      z-index: 0;
      height: 100%;
      width: 100%;
      mix-blend-mode: normal;
    }
    clip-path: url(#curve);
  }
  iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .9;
    // mix-blend-mode: overlay;
  }
  //slick slider area
  .carousel-hero {
    background-color: $primary-color;
    padding: 1em 1em 0 1em;
    margin: -3em 0em 2em 0em;
    border-radius: $global-radius;
    .slick-dots {
      position: relative;
      bottom: 1em;
    }
  }
  @include breakpoint(medium) {
    .carousel-hero {
      background-color: transparent;
      padding: 1em 1em 0 1em;
      margin: 0;
      .slick-dots {
        bottom: -20px;
      }
    }
    //makes content area fill space
    .hero-content-wrapper {
      width: 100vw;
      min-height: 73vh;
      &.baiting-landing{
        min-height: 53vh;
      }
      @include breakpoint(medium) {
        min-height: 80vh;
        &.baiting-landing{
          min-height: 60vh;
        }
      }
      align-items: center;
    }
  }
  .hero-caption {
    text-align: left;
    h1, h2, h3, p {
      font-weight: 300;
      color: $white;
      line-height: 1em;
    }
    h1 {
      text-shadow: .05em .05em 0 $primary-color;
    }
    h2 {
      font-family: $body-font-family;
      margin: 0;
      text-shadow: .05em .05em 0 $white;
    }
    p {
      margin-top: 1em;
      font-weight: bold;
      color: $white;
      a {
        color: $gold;
        text-decoration: underline;
        &:hover {
          text-underline-position: below;
          text-underline-offset: .25em;
        }
      }
    }
    .button {
      margin: 0 auto 2em auto;
      //@include button-hover-white;
      text-transform: uppercase;
      letter-spacing: .15em;
      font-family: $header-font-family;
      border: 2px solid $secondary-color;
      small {
        display: block;
        margin-top: .25em;
        font-size: .55em;
        letter-spacing: initial;
      }
      &:hover {
        border: 2px solid $yellow;
        box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, .5);
      }
    }
  }
  @include breakpoint(medium) {
    .hero-caption {
      p {
        font-size: $paragraph-font-size + 0.25;
      }
      .button {
        margin: 1em 0 0 0;
      }
    }
  }
  @include breakpoint(large) {
    .hero-caption {
      h1 {
        font-size: f-header-size(medium, h1) + .5;
      }
      h2 {
        @include header-size(h2);
      }
      .button.large {
        font-size: f-header-size(medium, h2);
      }
      p {
        font-size: $paragraph-font-size + 0.4;
      }
    }
  }
  @include breakpoint(xlarge) {
    .hero-caption {
      h1 {
        font-size: f-header-size(medium, h1) + 1;
        color: $white;
      }
      h2 {
        font-size: f-header-size(medium, h2) + 1;
      }
    }
  }
  .aside-form {
    border-radius: 1em;
    padding-top: 1em;
    background-color: $gold;
    z-index: 3; //added 8/22-22
    .nf-form-cont {
      background-color: $transparent;
      border: none;
      box-shadow: none;
      h3 {
        color: $primary-color;
        text-shadow: 2px 2px 0px $white;
        padding-top: .5em;
      }
      label {
        color: $primary-color;
      }
      .nf-form-fields-required {
        color: $primary-color;
      }
    }
  }
}
.price-table-container {
  @include breakpoint(large) {
    background-color: $white;
    border-radius: 1em;
    box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, .5);
    margin: 50px 0;
  }
  h3 {
    color: $primary-color;
  }
}
.aside {
  .card {
    border: 1px solid $gold;
    border-radius: 1em;
    box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, .5);
    &.light-gray {
      background-color: $light-gray;
    }
    &.primary-color {
      background-color: $primary-color;
      p {
        color: $white;
      }
      a.button {
        background-color: $secondary-color;
        &:hover {
          color: $secondary-color;
          background-color: $white;
        }
      }
    }
    &.secondary-color {
      background-color: $secondary-color;
      p {
        color: $white;
      }
    }
    &.gold {
      background-color: $gold;
      a.button {
        &:hover {
          color: $white;
          background-color: $secondary-color;
        }
      }
    }
  }
  .card-divider {
    background-color: $gold;
    background: linear-gradient(0deg, rgba($gold, 1) 0%, rgba($yellow, 1) 50%, rgba($gold, 1) 100%);
    h5 {
      color: $primary-color;
    }
  }
  .card-section {
    color: $primary-color;
  }
  .accordion-menu {
    a {
      padding: .7em 0;
    }
  }
  .nf-form-title {
    display: none;
  }
}
.blog-cat-button {
  margin-top: 15px;
}
.aside-form {
  background-color: $primary-color;
  .card-section {
    color: $white;
    a {
      color: $secondary-color;
    }
  }
  // Ninja Form
  .nf-form-cont {
    //h1, h2, h3, h4, p {
    //  color: $white!important;
    //}
    color: $white;
    background: $primary-color;
    border-radius: 1em;
    padding: 0;
    border: 2px solid lighten($primary-color, .5);
    box-shadow: 0px 8px 16px -8px rgba($primary-color, .5);
    box-shadow: 0px 0px 20px 0px rgba($primary-color, 0.75) inset;
    @include breakpoint(medium) {
    }
    h3 {
      color: $white;
      text-shadow: 2px 2px 0px $secondary-color;
      letter-spacing: 1px;
      position: relative;
    }
    .nf-field-label {
    }
    label {
      color: $gold;
      font-size: 1em;
      line-height: 1em;
    }
    textarea, input:not(.signup-button) input.ninja-forms-field:not([type="button"]),input:not([type="submit"]), select.ninja-forms-field:not([multiple]), [type=text] {
      background: rgba($white, .9) ;
      border: inherit;
      border-width: 2px !important;
      border-color: $primary-color;
      border-radius: $global-radius;
      box-shadow: none;
      font-family: $input-font-family;
      font-size: $input-font-size;
      font-weight: $input-font-weight;
      line-height: $input-line-height;
      border: $input-border;
      padding: $input-padding !important;
      //z-index: 0;
      &::placeholder {
        color: $input-placeholder-color;
      }
      &:focus {
        background: $input-background-focus !important;
        border: $input-border-focus;
        color: $input-color;
      }
    }
    .list-select-wrap .nf-field-element > div {
      background: rgba($white, .9) !important;
    }
    .nf-form-fields-required {
      color: $white;
      font-size: .8em;
    }
    .listselect-wrap .nf-field-element div, input.ninja-forms-field, select.ninja-forms-field:not([multiple]), [type=text] {
      height: 40px !important;
      border-radius: $global-radius;
    }
    select.ninja-forms-field:not([multiple]) {
      background: none !important;
    }
    input[type=button],
    input[type=submit] {
      @include button($background: $secondary-color);
      background: $secondary-color;
      @include button-hover-white;
    }
    #ninja_forms_required_items, .nf-field-container {
      margin-bottom: .5em;
    }
    .nf-form-content {
      // background-color: $primary-color
    }
    .list-select-wrap div:after {
      color: $secondary-color !important;
      border-radius: $global-radius;
    }
  }
}
.home-hero {
  .signup-button {
    @include button($background: $secondary-color);
    background: $secondary-color;
    @include button-hover-white;
  }
}
.subpage {
  .aside {
    .card {
      border: 1px solid $gold;
      border-radius: 1em;
      box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, .5);
    }
    .card-divider {
      background-color: $secondary-color;
      background: linear-gradient(0deg, $secondary-color 0%, darken($secondary-color, 2%) 100%);
      h5 {
        color: $white;
      }
    }
  }
}
.aside {
  .quote-slider {
    blockquote {
      margin: 0;
      padding: 0;
      border-left: none;
      p {
        margin: 0;
        letter-spacing: 0;
        font-size: 1em;
        font-family: $font-family-serif;
        &:before {
          content: '“';
        }
        &:after {
          content: '”';
        }
      }
      footer {
        margin: 10px 0;
        font-style: italic;
        font-family: $font-family-serif;
        font-weight: bold;
      }
    }
  }
}
.price-table {
  &.large-table-header {
    padding: 0 0 1em 0;
    margin-top: 1em;
    background: linear-gradient(0deg, rgba($primary-color, .5) 0%, rgba($primary-color, .75) 100%);
    background: linear-gradient(180deg, rgba($white, 0) 0%, rgba($light-gray, .5) 100%);
  }
  .price-feature {
    font-size: .8em;
    font-weight: bold;
    color: $primary-color;
    font-stretch: condensed;
    @include breakpoint(large) {
      font-size: .85em;
      line-height: 1em;
    }
  }
  .price-badge {
    max-height: 120px;
    margin: .5em auto;
    @include breakpoint(large) {
      max-height: 120px;
    }
    @include breakpoint(xlarge) {
      max-height: 150px;
    }
  }
  .price-block {
    box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, .5);
    margin: 1em !important;
    border-radius: 1em;
    background-color: $white;
    @include breakpoint(large) {
      box-shadow: none;
      margin: 0 !important;;
      border-radius: 0;
      background-color: $transparent;
      //&:hover {
      //  box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, .5);
      //}
    }
  }
  .table-column {
    min-height: 40px;
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .small-up-1 > .table-column {
    &:nth-last-of-type(even) {
      background-color: $light-gray;
    }
  }
  .table-column-price {
    font-size: f-header-size(small, h3);
    font-weight: bolder;
    font-family: $font-family-serif;
    min-height: 60px;
    text-align: center;
    justify-content: center;
    .small {
      display: block;
      font-size: .75em;
    }
  }
  .table-column-link {
    text-align: center;
    justify-content: center;
    .button:hover {
      background-color: $gold;
    }
  }
}
.check {
  &:before {
    content: "";
    display: inline-block;
    width: 2em;
    height: 2em;
    font-family: aktiv-base;
    font-size: 1.25em;
    font-style: normal;
    content: "\e800";
    color: $primary-color;
    text-shadow: 2px 2px $gold;
  }
}
.pest-pricing-list {
  li {
    list-style: none;
    display: flex;
    &:before {
      content: "";
      display: flex;
      flex-shrink: 0;
      width: 2em;
      height: 2em;
      font-family: aktiv-base;
      font-size: 1.25em;
      font-style: normal;
      content: "\e800";
      color: $primary-color;
      text-shadow: 2px 2px $gold;
    }
  }
  .row {
    padding-top: 2em;
    border-top: 1px solid $light-gray;
  }
}
.hover-callouts {
  margin: 0 auto;
  > div {
    vertical-align: bottom;
    transition: all 0.5s;
    position: relative;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
    overflow: hidden;
    background-clip: content-box !important;
    min-height: 300px;
    display: table;
    border: 4px solid $white;
    &:first-of-type {
      border-bottom: 4px solid $white;
    }
    > div {
      border-collapse: collapse;
      display: table-row;
      background-color: rgba($dark-gray, .9);
    }
    .meta {
      height: 96%;
      text-align: center;
      border: 1px solid $transparent;
      padding: 0.5em;
      margin: 0.5em 0 0;
      display: table-cell;
      vertical-align: middle;
      h3,
      h4 {
        font-weight: 400;
        color: $white;
        margin-bottom: 0;
        line-height: 1.25em;
      }
      hr {
        margin: 0.5em 0;
        border-color: teal;
      }
      p {
        color: $white;
        margin: 1em 0;
      }
    }
    @include breakpoint(medium) {
      overflow: hidden;
      background-size: cover !important;
      transition: all 0.25s;
      min-height: 400px;
      display: block;
      > div {
        background-color: rgba($dark-gray, 0.65);
        transition: all 0.5s;
        height: 100%;
        padding: 0.5em;
        display: block;
      }
      .meta {
        transform: translate(0px, 40%);
        transition: all 0.5s;
        display: block;
        h3 {
          color: $white;
        }
        p {
          color: $white;
          opacity: 0;
          transition-delay: 0;
        }
        hr {
          opacity: 0;
          transition-delay: 0;
          width: 80%;
          margin-right: auto;
          margin-left: auto;
        }
        a {
          opacity: 0;
          transition-delay: 0;
        }
      }
      &:hover {
        > div {
          background-color: rgba($dark-gray, .9);
        }
        .meta {
          transform: translate(0px, 30%);
          transition-delay: 0.25s;
          p {
            opacity: 1;
            transition: all 1s;
            transition-delay: 0.25s;
          }
          hr {
            opacity: 1;
            transition-delay: 0.25s;
          }
          a {
            opacity: 1;
            transition: all 1s;
            transition-delay: 0.5s;
          }
        }
      }
    }
    &:hover {
      background-size: 120%;
    }
  }
}
.stats {
  margin-top: 2em;
  font-family: $header-font-family;
  font-weight: 300;
  line-height: 1.25em;
  text-align: center;
  .column {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  strong {
    font-weight: 600;
    display: block;
    font-size: 1.5em;
    line-height: 1.5em;
    color: $primary-color;
  }
  @include breakpoint(large) {
    font-size: 1em;
  }
}
.testimonials {
  text-align: center;
  .row {
    align-items: center;
    justify-content: center;
  }
}
.quote-slider {
  blockquote {
    margin: 30px 0 0;
    border-left: none;
    p {
      margin: 0;
      letter-spacing: 0.1em;
      &:before {
        content: '“';
      }
      &:after {
        content: '”';
      }
    }
    footer {
      margin: 10px 0;
      font-style: italic;
      font-family: $font-family-serif;
    }
    @include breakpoint(medium) {
      p {
        font-size: 120%;
      }
    }
  }
}
.showroom-callout {
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba($white, .5);
    top: 0;
  }
}
.client-logos {
  background-color: $blue;
  .ri-grid {
    margin: 0 auto;
    position: relative;
    height: auto;
  }
  .ri-grid ul {
    list-style: none;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  /* Clear floats by Nicolas Gallagher: http://nicolasgallagher.com/micro-clearfix-hack/ */
  .ri-grid ul:after,
  .ri-grid ul:before {
    content: '';
    display: table;
  }
  .ri-grid ul:after {
    clear: both;
  }
  .ri-grid ul {
    zoom: 1;
    /* For IE 6/7 (trigger hasLayout) */
  }
  .ri-grid ul li {
    perspective: 150px;
    //height: 200px!important;
    width: 100%;
    margin: 0;
    padding: 0;
    float: left;
    position: relative;
    display: block;
    overflow: hidden;
    //border: 1em solid $white;
    @include breakpoint(medium) {
      //border: 1em solid transparent;
    }
  }
  .ri-grid ul li a,
  .ri-grid ul li div {
    display: block;
    outline: none;
    position: absolute;
    z-index: 1002;
    margin: 0 auto;
    padding: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    background-size: 90%;
    background-position: center center;
    background-repeat: no-repeat;
    box-sizing: content-box;
    transition: all 0.25s ease-in-out;
  }
  /* Grid wrapper sizes */
  .ri-grid-size-1 {
    width: 55%;
  }
  .ri-grid-size-2 {
    width: 100%;
  }
  .ri-grid-size-3 {
    width: 100%;
    //margin-top: 0;
  }
  .ri-grid-loading:after,
  .ri-grid-loading:before {
    display: none;
  }
  .ri-loading-image {
    display: none;
  }
  .ri-grid-loading .ri-loading-image {
    position: relative;
    width: 30px;
    height: 30px;
    left: 50%;
    margin: 100px 0 0 -15px;
    display: block;
  }
}
// PRICING CHART
//////////////////////////////////////////////////////////////////////////
.pricing-chart {
  tbody td {
    text-align: center;
  }
}
// ABOUT
//////////////////////////////////////////////////////////////////////////
.landing {
  h2 {
    //display: initial;
  }
  .hero {
    min-height: 300px;
    background-size: 100%;
    background-position: center center;
    //margin-bottom: 2.500em;
    position: relative;
    @include breakpoint(medium) {
      position: relative;
      mask-image: url('../images/swoosh-down.svg');
      mask-mode: alpha;
      mask-size: 100%;
      mask-position: center bottom;
      margin-bottom: -80px;
      padding-bottom: 80px;
    }
    &:before {
      @include overlay;
    }
    .hero-caption {
      color: white;
      text-align: center;
      transform: translate(0%, -50%);
      top: 50%;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      h1,
      h2 {
        color: $primary-color;
        text-shadow: 1px 1px $white;
      }
      h2 {
        font-weight: 300;
        font-size: f-header-size(medium, h2) - 1;
      }
      h1 {
        font-size: f-header-size(medium, h1) - 0.5;
        text-transform: capitalize;
        font-weight: 700;
      }
      p {
        font-size: $paragraph-font-size;
        a {
          color: white;
          font-weight: 700;
        }
      }
    }
    @include breakpoint(medium) {
      min-height: 350px;
      background-size: cover !important;
      background-position: center top;
      margin-bottom: 1em;
    }
    @include breakpoint(large) {
      min-height: 400px;
      .hero-caption {
        h1 {
          font-size: f-header-size(medium, h1) + 0.5;
        }
        h2 {
          @include header-size(h2);
        }
      }
    }
    @include breakpoint(xlarge) {
      min-height: 550px;
      .hero-caption {
        padding-top: 4em;
        // h1 {
        //    font-size: f-header-size(medium, h1) + 1.25;
        // }
        // h2 {
        //    font-size: f-header-size(medium, h2) + 1;
        // }
      }
    }
  }
  //.stak:nth-child(even) {
  //  background-color: $light-gray;
  //}
}
// FLEXIBLE CONTENT BLOCKS
.photo-bgd-callout {
  background-position: center top !important;
  background-size: cover;
  position: relative;
  &:before {
    @include overlay;
  }
  &.gold-stak {
    &:before {
      background: linear-gradient(0deg, rgba($gold, .5) 0%, rgba($gold, .75) 100%);
    }
  }
  .content {
    margin: 2em auto;
    padding: 1em;
    text-align: center;
    color: $white;
    background-color: $primary-color;
    border-radius: 1em;
    h3 {
      color: $white;
      text-shadow: .05em .05em 0 $secondary-color;
    }
    a:not(.button) {
      color: $secondary-color;
      font-weight: bold;
      white-space: nowrap;
      &:hover {
        color: $secondary-color;
      }
    }
    //.button {
    //  &:hover {
    //    box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, .5);
    //    background-color: $white;
    //  }
    //}
  }
  @include breakpoint(medium) {
    .content {
      h3 {
        // color: $secondary-color;
      }
      margin: 2em auto;
      padding: 1em 1em;
      width: 340px;
      height: 340px;
      //border-radius: 100%;
      //background-color: rgba($gold, .9);
      background-color: $primary-color;
      border-radius: 100%;
      padding: 1em 1em 0 1em;
      border: 2px solid lighten($primary-color, 20%);
      box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, .5);
      box-shadow: 0px 0px 20px 0px rgba($primary-color, 0.75) inset;
    }
  }
}
.cards-stak {
  .card {
    border-radius: 1em;
    box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, .5);
  }
  .card-section {
    text-align: center;
  }
  .card-description {
    margin-bottom: 1em;
  }
  a.button {
     background: $red;
    display: inline-block;
    margin: 15px 0;
    &:hover{
      color: $white;
      background: $gold;
    }
  }
  &.blue-cards {
    text-align: center;
    .card {
      background: $secondary-color;
      border-bottom: 5px solid $primary-color;
      color: $white;
    }
  }
}
.card-overlap {
  @include breakpoint(large) {
    margin-top: -60px;
  }
  z-index: 1000;
}
// VIDEO
.flex-video {
  iframe {
    border: 0;
  }
}
.video-backbground {
  background-color: $light-gray;
  padding: 15px;
  margin: 0 15px !important;
}
.video-player {
  // background-color: #fff;
  padding-bottom: 5px;
  .poster {
    position: relative;
    img {
      width: 100%;
    }
  }
  .play-button {
    position: absolute;
    width: 80px;
    height: 80px;
    margin: auto;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    transition: opacity 0.5s ease;
    &:hover {
      opacity: 0.5;
    }
  }
}
.video-cards {
  .tease-post {
    .card {
      padding: 0;
      margin: 0 0 1em 0;

      picture {
        position: relative;
        &:before {
          content: url("../images/ico-video-play.svg");
          position: absolute;
          width: 50px;
          height: 50px;
          margin: auto;
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;
        }
      }
    }
    .card-section {
      p {
        margin: 1em auto 1em;
      }
    }
    a {
      h5:hover {
        color: $secondary-color;
      }
    }
  }
}
.split-image {
  img {
    margin-bottom: 2em;
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }
}
h3,
h4 {
  padding-top: 1em;
  &.underline {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background-color: $gray;
    }
  }
  line-height: 1.15em;
}
//.align-middle,
//.collapse,
//.fill-image {
//  .content {
//    padding: 2em;
//  }
//  @include breakpoint(xlarge) {
//    .content {
//      padding: 4em;
//    }
//  }
//}
.fill-image {
  .columns {
    min-height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    @include breakpoint(medium) {
      background-size: contain;
    }
  }
}
.alternating-staks {
  .stak {
    &:nth-child(odd) {
      background-color: $light-gray;
    }
  }
  img.thumbnail {
    margin: 0.5em auto;
    width: 90%;
  }
}
.gallery-photo {
  &.slick-slider {
    margin-bottom: 4px !important;
  }
  > div {
    // max-width: 320px;
    overflow: hidden;
    vertical-align: middle;
    margin-bottom: 0;
    img {
      //max-width: 640px;
      width: 100%;
      vertical-align: middle;
      margin: 0 auto;
      transform: scale(1);
      transition: transform 0.25s;
    }
  }
}
.gallery-thumb {
  //max-width: 640px;
  max-height: 98px;
  background-color: $white;
  div.slick-slide {
    //min-width: 120px;
    max-height: 90px !important;
    overflow: hidden;
    vertical-align: middle;
    margin: 0;
    text-align: center;
    margin: 0 2px;
    .slick-list {
      max-height: 90px;
    }
    img {
      max-width: 120px;
      min-height: 90px;
      vertical-align: middle;
      padding: 0 4px 0 0;
      margin: 0 auto;
    }
    @include breakpoint(large) {
      img {
        max-width: 150px;
        min-height: 120px;
      }
    }
  }
  .slick-next,
  .slick-prev {
    top: 0;
    background-color: rgba($white, .9);
    z-index: 1;
    height: 100%;
    &:before {
      color: $black;
    }
  }
}
.photo-gallery {
  justify-content: center;
  text-align: center;
}
.list-block-stak {
  &.pests-callout {
    .circle-pest {
      position: relative;
      background: linear-gradient(to bottom, $yellow, $gold);
      border-radius: 100%;
      display: block;
      box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, .5);
      margin-bottom: 1em;
      transition: all .25s ease-in-out;
      img {
        padding: .7em;
        border-radius: 100%;
        transition: all .25s ease-in-out;
      }
      &:hover {
        background: linear-gradient(to top, $yellow, $gold);
        box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 1);
        img {
          opacity: 1;
        }
      }
    }
  }
}
// BLOG
/////////////////////////////////
// Article
.more-articles {
  padding-top: 2em;
  .button {
    border-radius: $button-radius;
  }
}
.article-cards, .tease-post, .tease-page {
  .card {
    transition: all 0.5s;
    display: block;
    //background-color: $light-gray;
    overflow: hidden;
    //margin: .9em;
    margin: 0 0 1em 0;
    p {
      font-size: .8em;
      margin: 1.5em auto 1.75em;
    }
    .date {
      color: $gray;
      font-size: .8em;
    }
    .article-thumb {
      width: 100%;
      display: block;
      position: relative;
    }
    .article-categories {
      margin-bottom: 1em;
      a {
        //text-transform: uppercase;
        font-size: .8em;
        color: $secondary-color;
        font-weight: bold;
        &:hover {
          color: lighten($primary-color, 8%);
        }
        &:before {
          content: ", ";
        }
      }
      a:first-of-type {
        &:before {
          content: "";
        }
      }
    }
    &:hover {
      img {
        width: 110%;
      }
    }
  }
  @include breakpoint(medium) {
    .card {
      transition: all 0.25s;
      display: block;
      .card-section {
        transition: all 0.5s;
        padding: 1.5em 1em;
      }
    }
    // padding-bottom: 2em;
  }
}
.breadcrumbs {
  margin-top: 2em;
  vertical-align: middle;
  font-size: 0.85em;
  color: $gray;
  a {
    color: $secondary-color;
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    //font-size: 1em;
  }
  .breadcrumb_last {
    color: $gray;
    cursor: not-allowed;
  }
}
.pagination-block {
  text-align: center;
  border-top: 2px solid $light-gray;
  padding-top: 2em;
  .disabled {
    color: lighten($gray, 20%);
  }
  .prev {
    float: left;
  }
  .next {
    float: right;
  }
  .current {
    font-weight: bold;
  }
}
.article {
  h2, h3, h4 {
    color: $dark-gray;
    font-weight: normal;
    b {
      font-weight: normal;
    }
  }
  p {
    a {
      color: lighten($primary-color, 20%);
      &:hover {
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }
  .cat-title-link {
    a {
      padding: 0 .25em;
      &:after {
        content: ","
      }
      &:last-of-type {
        &:after {
          content: "";
        }
      }
    }
  }
  .featured-image {
    margin-bottom: 2em;
  }
  .article-pagenation {
    margin: 2em auto;
    padding: 2em;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    a {
      h4 {
        color: $dark-gray;
        //text-decoration: underline;
        font-size: .9em;
        margin-bottom: 0;
      }
      span {
        font-size: .8em;
        line-height: 1em;
        display: block;
        color: lighten($primary-color, 20%);
      }
      &:hover {
        h4, span {
          color: $secondary-color !important;
        }
      }
    }
    @include breakpoint(medium) {
      justify-content: space-between;
      .next-post {
        text-align: right;
      }
    }
  }
}
.recent-articles-list {
  margin-bottom: 1em;
  img {
    margin-right: 1em;
  }
  .article-date {
    font-size: .8em;
    margin-bottom: 0;
  }
  .article-title {
    h6 {
      margin-top: 0;
    }
    a:hover h6 {
      //   color: lighten($primary-color, 8%);
    }
  }
}
.category-list {
  a {
    padding: 0.7rem 1rem .7rem 0;
    display: inline;
    font-size: .8em;
  }
  .article-count {
    display: inline-block;
    width: 1.5em;
    font-size: 1em;
    color: $gray;
    //margin-right:  1rem;
  }
}
// Identify your Pest
.subnav {
  margin: 1em 0;
  .menu.scrollto {
    align-items: center;
    a {
      color: $secondary-color;
      padding: 0.7rem 1rem .7em 0;
      &:hover {
        text-decoration: underline;
        text-underline-position: below;
        text-underline-offset: 2px;
        text-decoration-color: $primary-color;
      }
    }
  }
}
.accordion-modal-header {
  border-bottom: 4px solid $primary-color;
  margin-bottom: 1em;
}
.accordion-header {
  justify-items: center;
  justify-content: center;
  margin-bottom: 2em;
  h2, h3, h4 {
    text-align: center;
    margin: 0 auto;
    display: block !important;
    padding-top: 0;
  }
  &:hover {
    .circle-pest {
      position: relative;
      background: linear-gradient(to bottom, $gold, $yellow);
      box-shadow: 0px 12px 16px -12px rgba(0, 0, 0, .5);
      transform: translateZ(0) scale(1.1) translate(0, -2px);
    }
  }
}
.indentify-your-pest {
  .accordion-stak {
    padding-bottom: 2em;
  }
}
.accordion-stak {
  .circle-pest {
    position: relative;
    background: linear-gradient(to bottom, $yellow, $gold);
    border-radius: 100%;
    display: block;
    box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, .5);
    margin: 0 auto 1em auto;
    transition: all .25s ease-in-out;
    padding: .7em;
    //@include breakpoint(medium) {
    //  display: inline-block;
    //  margin-right: .5em;
    //}
  }
}
.accordion-title {
  padding: .25rem .25rem .5em .25em;
  .title {
    font-family: $header-font-family;
    padding: .25em;
    font-size: 1.45em;
    line-height: 1.75;
    display: inline-block;
    vertical-align: middle;
  }
  .round {
    border-radius: 50%;
    overflow: hidden;
    width: 80px;
    height: 80px;
    margin-right: .5em;
    display: inline-block;
    //border-color: $primary-color;
    //border-width: 4px;
    //border-style: solid;
    position: relative;
    vertical-align: middle;
    img {
      min-height: 100%;
    }
  }
  @include breakpoint(medium) {
    padding: .5rem 1rem;
    .title {
      font-size: 2em;
      padding: .5rem 2rem;
    }
    .round {
      width: 110px;
      height: 110px;
      border-width: 8px;
    }
  }
}
.is-active {
  .accordion-title {
    background-color: $gold;
  }
}
.accordion-content {
  border: none;
}
//Alternative Accordion class for slimmer accordions
.accordion-slim {
  .accordion-title {
    .title {
      padding: 0 10px !important;
      font-size: 1.45em !important;
    }
  }
}
.tab-columns-stak {
  .tabs-wrapper {
    margin-top: 2em;
  }
  .tabs {
    background-color: transparent;
    border: none;
    .tabs-title {
      background-color: transparent;
      padding: 0;
      margin: 0;
      border-right: 4px solid transparent;
      transition: all .25s;
      .tabs-icon {
        max-height: 1.25em;
        display: inline-block;
        padding-right: .5em;
      }
      &.is-active {
        border-right: 4px solid $primary-color;
        background: linear-gradient(90deg, rgba($light-gray, .0) 0%, rgba($light-gray, .20) 100%);
        > a {
          color: $secondary-color;
        }
      }
      &:hover {
        border-right: 4px solid $secondary-color;
        background-color: transparent;
        > a {
          color: lighten($primary-color, 14%);
        }
      }
      > a {
        transition: all .25s;
        color: $primary-color;
        padding-left: .5em;
        cursor: pointer;
        font-size: 1em;
        font-weight: 600;
        margin-right: .5rem;
        border-top: 1px solid $light-gray;
        &[aria-selected='true'], &:focus, &:hover {
          background-color: transparent;
        }
      }
    }
  }
  .tabs-content {
    .tabs-panel {
      padding: 0 1em;
    }
    .tabs-panel.is-active {
      display: block;
      animation: fadein-animation .7s ease-in-out;
      opacity: 1;
    }
    // > .tabs-title:last-of-type {
    //    > a {
    //       border-bottom: 1px solid $light-gray;
    //    }
    // }
  }
}
.myimg {
  width: 165px;
  height: 165px;
  object-fit: cover;
  border-radius: 50%;
  border: 5px solid $white;
}
//Additional Custom CSS Classes
.reduced-padding-30 {
  padding: 30px 0;
}
.reduced-padding-15 {
  padding: 15px 0;
}
// .comment-box {
//    margin-top: 2em;
//    padding-top: 2em;
//    border-top: 4px solid $light-gray;
// }
// .comment-form {
//    input, textarea {
//       border: none;
//       background-color: $light-gray;
//       box-shadow: none;
//       color: $gray;
//       &::placeholder {
//          color: lighten($gray, 20%);
//       }
//       &:focus::placeholder{
//          color:transparent;
//       }
//    }
//    input {
//       margin-bottom: 1em;
//    }
//    label {
//       display: none;
//    }
//    textarea {
//       min-height: 148px;
//    }
//    sup {
//       display: block;
//       font-family: $font-family-serif;
//       margin: 1em auto;
//       font-style: italic;
//       color: $gray;
//    }
//    h4 {
//       display: block;
//       font-style: normal;
//       text-transform: uppercase;
//       font-size: f-header-size(small, h6)*.8;
//       color: $primary-color;
//       font-weight: bold;
//       margin-bottom: 1em;
//    }
// }
// LEADERSHIP
.subpage {
  h2 {
    //display: initial;
  }
  &.article {
    .hero {
      min-height: 150px;
      @include breakpoint(medium) {
        min-height: 370px;
      }
      @include breakpoint(large) {
      }
      @include breakpoint(xlarge) {
      }
      &:before {
        background-image: url("../images/bgd-pests.png");
        background-size: cover;
      }
      .blog-title {
        font-family: FreemontBold;
        font-style: normal;
        font-size: 2.5rem;
        font-weight: 700;
        color: $white;
      }
    }
  }
  .hero {
    min-height: 240px;
    background-size: 100%;
    background-position: center top;
    //margin-bottom: 2.500em;
    position: relative;
    background-color: $gold;
    z-index: -1;
    background-repeat: no-repeat;
    @include breakpoint(medium) {
      position: relative;
      mask-image: url('../images/swoosh-down.svg');
      mask-mode: alpha;
      mask-size: 100%;
      mask-position: center bottom;
      margin-bottom: -80px;
      padding-bottom: 80px;
    }
    &:before {
      @include overlay-black;
    }
    .hero-caption {
      color: white;
      text-align: center;
      transform: translate(0%, -50%);
      top: 50%;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      h1,
      h2 {
        color: $white;
        text-shadow: 0px 0px 9px $primary-color;
        //-webkit-text-stroke: 1px $white;
      }
      h2 {
        font-weight: 300;
        font-size: f-header-size(medium, h2) - 1;
      }
      h1 {
        font-size: f-header-size(medium, h1) - 0.5;
        font-weight: 700;
      }
      p {
        font-size: $paragraph-font-size;
        a {
          color: white;
          font-weight: 700;
        }
      }
    }
    @include breakpoint(medium) {
      min-height: 300px;
      background-size: cover !important;
      background-position: center center;
      margin-bottom: 1em;
    }
    @include breakpoint(large) {
      min-height: 325px;
      background-size: cover !important;
      .hero-caption {
        h1 {
          //font-size: f-header-size(medium, h1);
        }
        h2 {
          @include header-size(h2);
        }
      }
    }
    @include breakpoint(xlarge) {
      min-height: 350px;
      .hero-caption {
        // h1 {
        //    font-size: f-header-size(medium, h1) + 1.25;
        // }
        // h2 {
        //    font-size: f-header-size(medium, h2) + 1;
        // }
      }
    }
  }
  //&.news-events{
  //  .hero{
  //    &:before{
  //      background-image: url("../images/bgd-pests.png");
  //    }
  //  }
  //}
}
.default {
  .hero {
    min-height: 240px;
    background-size: 100%;
    background-position: center top;
    //margin-bottom: 2.500em;
    position: relative;
    background-color: $gold;
    z-index: -1;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    display: flex;
    @include breakpoint(medium) {
      position: relative;
      mask-image: url('../images/swoosh-down.svg');
      mask-mode: alpha;
      mask-size: 100%;
      mask-position: center bottom;
    }
    &:before {
      @include overlay;
    }
    .hero-caption {
      color: white;
      margin: 0 auto;
      z-index: 2;
      h1,
      h2 {
        color: $primary-color;
        text-shadow: 1px 1px $white;
      }
      h2 {
        font-weight: 300;
        font-size: f-header-size(medium, h2) - 1;
      }
      h1 {
        font-size: f-header-size(medium, h1) - 0.5;
        font-weight: 700;
      }
      p {
        font-size: $paragraph-font-size;
        a {
          color: white;
          font-weight: 700;
        }
      }
    }
  }
}
.no-bg{
  &:before{
    background: none !important;
  }
}
//Locations Page - Smaller location images on mobile.
.locations{
  iframe{
    @include breakpoint(medium) {
      margin-top: 65px;
    }
  }
}
  img.small-img{
    max-width: 50%;
    @include breakpoint(medium) {
      max-width: 100%;
    }
  }

  .white-title{
    h1, h2, h3{
      color: $white;
    }
    h4{
      color: $blue;
    }
  }
// ANIMATIONS
//////////////////////////////////////////////////////////////////////////
.animate {
  opacity: 0;
}
.slideUp {
  animation-name: slideUp;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  25% {
    transform: translateY(-2%);
  }
  75% {
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
  }
}
.fadein {
  opacity: 0;
}
.fadeout {
  opacity: 1;
}
.fadein-animation {
  animation-name: fadein-animation;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes fadein-animation {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  25% {
    transform: translateY(0%);
  }
  75% {
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
  }
}
.fadeout-animation {
  animation-name: fadeout-animation;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes fadeout-animation {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  25% {
    transform: translateY(0%);
  }
  75% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 0;
    transform: translateY(50%);
  }
}
